<template>
    <div class="card">
        <div class="card-body">
            <template v-if="!loadingAutomation && automation">
                <div class="box-share">
                    <div class="icon">
                        <feather type="command" size="120" />
                    </div>
                    <div class="name">
                        {{ automation.name }}
                    </div>
                    <div class="action">
                        <button class="btn btn-primary" :disabled="loadingDuplicate" @click="duplicate">
                            <b-spinner v-if="loadingDuplicate" small /> <i class="uil uil-plus"></i> Add To Automations
                        </button>
                        <button class="btn btn-dark ml-2" @click="forget">
                            Forget
                        </button>
                    </div>
                </div>
            </template>
            <div v-else class="text-center">
                <b-spinner></b-spinner>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loadingAutomation: false,
                automation: null,
                loadingDuplicate: false,
            }
        },

        mounted() {

            this.getAutomation()
        },

        methods: {
            getAutomation() {
                this.loadingAutomations = true
                this.$store
                    .dispatch('automation/findByUniqueId', this.$route.params.id)
                    .then((automation) => {
                        this.loadingAutomations = false
                        this.automation = automation
                    })
                    .catch(() => {
                        this.$router.push({ name: 'business.automations.index' })
                        this.loadingAutomations = false
                    })
            },

            forget() {
                this.$router.push({ name: 'business.automations.index' })
            },

            duplicate() {
                this.loadingDuplicate = true
                this.$store
                    .dispatch('automation/duplicate', this.$route.params.id)
                    .then((res) => {
                        this.loadingDuplicate = false
                        this.$router.push({ name: 'business.automations.edit', params: {id: res.id} })
                    })
                    .catch(() => {
                        this.loadingDuplicate = false
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>
.box-share {
    text-align: center;
    padding: 50px 0px;
    .icon {
        display: inline-block;
        background: #ff694c;
        color: #fff;
        border-radius: 50%;
        padding: 26px;
    }
    .name {
        color: #394a55;
        font-weight: bold;
        font-size: 24px;
        margin: 20px 0px;
    }
}

</style>